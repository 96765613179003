import * as React from 'react'
import { LinkProps as RouterLinkProps } from 'react-router-dom'
import clsx from 'clsx'
import backArrowIcon from '@assets/icons/backArrowIcon.svg'
import { ClassNameProps } from '@controls/types'
import { RouterLink } from '@controls/buttons'
import styles from './BreadCrumbs.module.css'
import { FlexContainer } from '@layout/FlexContainer'

export type BreadCrumbsProps = Readonly<{
  breadCrumbs: Array<{ to?: RouterLinkProps['to']; title: string | React.ReactElement }>
}> &
  ClassNameProps

export const BreadCrumbs: React.FC<BreadCrumbsProps> = ({ className, breadCrumbs }) => {
  return (
    <FlexContainer fixedDirection alignItemsCenter noGap className={clsx(styles.breadCrumbsContainer, className)}>
      {breadCrumbs.map(({ to, title }, index) => (
        <React.Fragment key={index}>
          {index === 0 ? (
            <RouterLink className={styles.arrowLink} to={to ?? '/'}>
              <img src={backArrowIcon} alt="back" />
            </RouterLink>
          ) : (
            '/'
          )}
          {to ? (
            <RouterLink className={styles.underlinedLink} to={to}>
              {title}
            </RouterLink>
          ) : (
            <span>{title}</span>
          )}
        </React.Fragment>
      ))}
    </FlexContainer >
  )
}
